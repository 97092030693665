import * as React from "react";

import CharityGridList from '../charities/CharityGridList';

const Dashboard = () => {
    return (
        <div>
            <CharityGridList/>
        </div>
    );
};

export default Dashboard;
