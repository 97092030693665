import * as React from "react";
import { ReferenceArrayInput, 
        SimpleForm, TextInput, AutocompleteArrayInput, ImageField, ImageInput, ChipField } from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import RichTextInput from 'ra-input-rich-text';
import {Grid} from '@material-ui/core';


const useStyles = makeStyles({
    hidden: {
        display: "none"
    },
});


const CharityProfileForm = props => {
    const classes = useStyles();

    return (
        <SimpleForm {...props}>
            <TextInput fullWidth source="id" className={classes.hidden} disabled/>
            
            <TextInput label="resources.charities.fields.name" fullWidth source="name"/>

            <RichTextInput label="resources.charities.fields.description" fullWidth source="description" multiline/>

            <Grid container spacing={2} fullWidth>
                
                <Grid item xs={6}><TextInput label="resources.charities.fields.email" type="email" fullWidth source="email" /> </Grid>
                <Grid item xs={6}><TextInput label="resources.charities.fields.phone" type="phone" fullWidth source="phone"/></Grid>
                <Grid item xs={6}><TextInput label="resources.charities.fields.mobile" type="mobile" fullWidth source="mobile"/></Grid>
                <Grid item xs={6}><TextInput label="resources.charities.fields.website"  fullWidth source="website"/></Grid>
            </Grid>
            
            <Grid container spacing={2} fullWidth>
                
                <Grid item xs={6}><TextInput label="resources.charities.fields.address.address"  fullWidth source="address.address" /> </Grid>
                <Grid item xs={6}><TextInput label="resources.charities.fields.address.city"  fullWidth source="address.city"/></Grid>
                <Grid item xs={6}><TextInput label="resources.charities.fields.address.state"  fullWidth source="address.state"/></Grid>
                <Grid item xs={6}><TextInput label="resources.charities.fields.address.country"  fullWidth source="address.country"/></Grid>
                <Grid item xs={6}><TextInput label="resources.charities.fields.address.lat"  fullWidth source="address.lat"/></Grid>
                <Grid item xs={6}><TextInput label="resources.charities.fields.address.lng"  fullWidth source="address.lng"/></Grid>
            </Grid>

            <Grid container spacing={2} fullWidth>
                
                <Grid item xs={6}><TextInput label="resources.charities.fields.social.facebook"  fullWidth source="social.facebook"/> </Grid>
                <Grid item xs={6}><TextInput label="resources.charities.fields.social.instagram"  fullWidth source="social.instagram"/></Grid>
                <Grid item xs={6}><TextInput label="resources.charities.fields.social.twitter"  fullWidth source="social.twitter"/></Grid>
                
            </Grid>
            
          
            
            <ImageInput  source="logo" accept="image/*" fullWidth>
                <ImageField source="formats.small.url" title="title"/>
            </ImageInput>
                       

            <ReferenceArrayInput label="resources.charities.fields.managers" source="managers" reference="users"
                fullWidth>
                <AutocompleteArrayInput optionText="username">
                <ChipField source="username" />
                </AutocompleteArrayInput>
            </ReferenceArrayInput>



        </SimpleForm>
    );
};

export default CharityProfileForm;
