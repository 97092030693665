import * as React from "react";
import {Edit} from 'react-admin';
import {useParams} from "react-router-dom";
import CharityProfileForm from "./CharityProfileForm";


const RestaurantProfileEdit = props => {
    const {charityId} = useParams();
    
    return (
        <Edit basePath={`/`} resource={`charities`} id={charityId}>
           <CharityProfileForm {...props}/> 
        </Edit>
    );
};

export default RestaurantProfileEdit;
