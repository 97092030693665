import * as React from "react";
import {Create} from 'react-admin';
import ContactForm from "./ContactForm";
import Support from "./Support";

const ContactCreate = props => { 
     
    return (

        <>
        <Support/>

        <Create basePath={`/`}  resource={`contacts`}  >
            
            <ContactForm {...props}/>
        </Create>
        </>
    );
};

export default ContactCreate;
