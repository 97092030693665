import {fetchUtils} from 'react-admin';

import strapiRestProvider from '../overrides/ra-strapi-rest';


const baseURL = 'https://api.saborescv.com';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    if(localStorage.getItem('auth')){
    const {jwt} = JSON.parse(localStorage.getItem('auth'));
    options.headers.set('Authorization', `Bearer ${jwt}`);
    return fetchUtils.fetchJson(url, options);
    }
};

const adjustQueryForStrapi = (params) => {

    const queryParts = [];

    // Handle SORTING
    if (params.sort) {
        const s = params.sort;
        const sort = s.field === "" ? "_sort=updated_at:DESC" : ("_sort=" + s.field + ":" + s.order);
        queryParts.push(sort);
    }

    // Handle FILTER
    if (params.filter) {
        const f = params.filter;
        let filter = "";
        const keys = Object.keys(f);
        for (let i = 0; i < keys.length; i++) {
            //react-admin uses q filter in several components and strapi use _q
            if (keys[i] === "q" && f[keys[i]] !== '') {
                filter += "_q=" + f[keys[i]] + (keys[i + 1] ? "&" : "")
            } else {
                filter += keys[i] + "=" + f[keys[i]] + (keys[i + 1] ? "&" : "");
            }
        }
        if (params.id && params.target && params.target.indexOf('_id') !== -1) {
            const target = params.target.substring(0, params.target.length - 3);
            filter += "&" + target + "=" + params.id;
        }
        queryParts.push(filter);
    }

    // Handle PAGINATION
    if (params.pagination) {
        const {page, perPage} = params.pagination;
        const start = (page - 1) * perPage;
        const range = "_start=" + start + "&_limit=" + perPage;
        queryParts.push(range);
    }

    return queryParts.join("&");
}

const dataProvider = strapiRestProvider(
    baseURL,
    httpClient,
    ['assets', 'logo', 'avatar'],
    ['entries', 'ingredients', 'instructions', 'address', 'social']
);

const customEndpoints = {
    myCharities: (resource, params = {}) => {
        const url = `${baseURL}/my-charities?${adjustQueryForStrapi(params)}`;
        return httpClient(url).then((response) => {
            const {json, total} = response;
            return {
                data: json,
                total
            };
        });
    },
    linkRecipes: (id, data) => {
        const url = `${baseURL}/restaurants/${id}/link`;
        return httpClient(url, {
            method: 'PATCH',
            body: JSON.stringify(data),
        }).then(({ json }) => ({ data: json }));
    },
    unlinkRecipes: (id, data) => {
        const url = `${baseURL}/restaurants/${id}/unlink`;
        return httpClient(url, {
            method: 'PATCH',
            body: JSON.stringify(data),
        }).then(({ json }) => ({ data: json }));
    }
};

const scvDataProvider = (type, resource, params) => {
    if (customEndpoints.hasOwnProperty(type)) {
        return customEndpoints[type](resource, params);
    }
    return dataProvider(type, resource, params);
}

export default scvDataProvider;
