import * as React from 'react';
import {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';

import {
    Button,
    Card,
    CardActions,
    CircularProgress,
    TextField,
} from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {Notification, useTranslate, Link, useNotify, useRefresh} from 'react-admin';

import { lightTheme } from './themes';
import authProvider from "../providers/authProvider";

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'url(imgs/kitchen.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    logo: {
        width: '120px',
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    formWrapper: {
        minHeight: '328px'
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    backToLoginWrapper:{
        textAlign: 'center',
        margin: '1em 0',
    }

}));

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
        required
    />
);

const { Form } = withTypes();

const ForgotPasswordForm = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();

    const userString = localStorage.getItem('user');
    const email = userString ? JSON.parse(userString).email: '';


    const handleSubmit = useCallback((values) => {
        setLoading(true);
        authProvider.forgotPassword(values)
            .then((res) => {
                if (!res.ok) {
                    throw res.error()
                }
                notify(
                    translate('auth.messages.forgot_password.success.title'),
                    'success',
                    {
                        _: translate('auth.messages.forgot_password.success.description')
                    }
                );
            }).catch(() => {
                notify(
                    translate('auth.messages.forgot_password.error.title'),
                    'warning',
                    {
                        _: translate('auth.messages.forgot_password.error.description')
                    }
                );
            })
            .finally(() => {
                setLoading(false);
                refresh();
            });
    }, [notify, refresh, translate]);

    const validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }

        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}  className={classes.formWrapper}>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                {/* <Avatar className={classes.icon}>
                                    <LockIcon />
                                </Avatar> */}
                                <img className={classes.logo} src="logo-with-text-red.svg" alt="sabores cv logo"></img>
                            </div>
                            <div className={classes.hint}>
                              {translate('auth.forgot_password')}
                            </div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        name="email"
                                        // @ts-ignore
                                        component={renderInput}
                                        label={translate('auth.email')}
                                        disabled={loading}
                                        type="email"
                                        require
                                        initialValue={email}
                                    />
                                </div>


                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                     variant="contained"
                                     type="submit"
                                     color="primary"
                                     disabled={loading}
                                     fullWidth
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {translate('auth.send')}
                                </Button>
                            </CardActions>
                            <div className={classes.backToLoginWrapper}>
                               {email? <Link to="/login" >Back to login</Link> :
                               <Link to="/" >Back to home</Link>}
                            </div>


                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    );
};

ForgotPasswordForm.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const ForgotPasswordPage = (props) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <ForgotPasswordForm {...props} />
    </ThemeProvider>
);

export default ForgotPasswordPage;
