
const utils = {
    adjustQueryForStrapi: (params) => {

        const queryParts = [];

        // Handle SORTING
        if (params.sort) {
            const s = params.sort;
            const sort = s.field === "" ? "_sort=updated_at:DESC" : ("_sort=" + s.field + ":" + s.order);
            queryParts.push(sort);
        }

        // Handle FILTER
        if (params.filter) {
            const f = params.filter;
            let filter = "";
            const keys = Object.keys(f);
            for (let i = 0; i < keys.length; i++) {
                //react-admin uses q filter in several components and strapi use _q
                if (keys[i] === "q" && f[keys[i]] !== '') {
                    filter += "_q=" + f[keys[i]] + (keys[i + 1] ? "&" : "")
                } else {
                    filter += keys[i] + "=" + f[keys[i]] + (keys[i + 1] ? "&" : "");
                }
            }
            if (params.id && params.target && params.target.indexOf('_id') !== -1) {
                const target = params.target.substring(0, params.target.length - 3);
                filter += "&" + target + "=" + params.id;
            }
            queryParts.push(filter);
        }

        // Handle PAGINATION
        if (params.pagination) {
            const {page, perPage} = params.pagination;
            const start = (page - 1) * perPage;
            const range = "_start=" + start + "&_limit=" + perPage;
            queryParts.push(range);
        }

        return queryParts.join("&");
    }
};

export default utils;
