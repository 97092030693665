import * as React from "react";
import {useTranslate} from 'react-admin';

import {Card, CardContent, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import IconLocation from '@material-ui/icons/LocationOn';
import IconEmail from '@material-ui/icons/Email';
import PublicIcon from '@material-ui/icons/Public';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'bold',
        paddingBottom: '12px'
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '4px',
    },
    form: {
        marginTop: '16px'
    },
    icon: {
        marginRight: '4px'
    },
    card: {
        minHeight: '158px'
    }
}));

const Support = () => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div>
            <Grid container spacing={2} fullWidth>
                <Grid item xs={6}>
                    <Card className={classes.card}>
                        <CardContent>
                            <div className={classes.title}>{translate('resources.contacts.partners')}</div>

                            <div className={classes.item}>Quisque rutrum. Vestibulum purus quam</div>
                            <div className={classes.item}>Scelerisque ut, mollis sed, nonummy id</div>
                            <div className={classes.item}>Nullam tincidunt adipiscing enim</div>
                            <div className={classes.item}>Vestibulum dapibus nunc ac augue</div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card className={classes.card}>
                        <CardContent>
                            <div className={classes.title}>{translate('resources.contacts.contactInformation')}</div>
                            <div className={classes.item}>
                                <IconLocation
                                    className={classes.icon}
                                    fontSize="small"
                                    color="primary"
                                />
                                <div>Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu</div>
                            </div>
                            <div className={classes.item}>
                                <IconEmail fontSize={'small'} className={classes.icon}
                                                                     color="primary"/>
                                <div>Fusce convallis metus id felis</div>
                            </div>
                            <div className={classes.item}><PublicIcon fontSize={'small'} className={classes.icon}
                                                                      color="primary"/>
                                <div>Phasellus consectetuer elit</div>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
};

export default Support;
