import * as React from "react";
import {
        SimpleForm, TextInput,  ImageField, ImageInput, Link, useTranslate} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from '@material-ui/core';


const useStyles = makeStyles({
    hidden: {
        display: "none"
    },
    forgotPassword: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
});


const UserProfileForm = props => {
    const classes = useStyles();
    const translate = useTranslate();



    return (
        <>
        <SimpleForm {...props}>
            <TextInput fullWidth source="id" className={classes.hidden} disabled/>




            <Grid container spacing={2} fullWidth>
                <Grid item xs={6}><TextInput label="resources.users.fields.firstName" fullWidth source="firstName"/></Grid>
                <Grid item xs={6}><TextInput label="resources.users.fields.lastName" fullWidth source="lastName"/></Grid>
                <Grid item xs={6}><TextInput label="resources.users.fields.username" fullWidth source="username"/></Grid>
                <Grid item xs={6}><TextInput  label="resources.users.fields.email" type="email" fullWidth source="email" disabled/></Grid>
                <Grid item xs={6}><TextInput label="resources.users.fields.phone" type="phone" fullWidth source="phone"/></Grid>
                <Grid item xs={6}><TextInput  label="resources.users.fields.mobile" type="mobile" fullWidth source="mobile"/></Grid>
            </Grid>

            <Grid container spacing={2} fullWidth>

                <Grid item xs={6}><TextInput  label="resources.users.fields.address.address"  fullWidth source="address.address" /> </Grid>
                <Grid item xs={6}><TextInput  label="resources.users.fields.address.city" fullWidth source="address.city"/></Grid>
                <Grid item xs={6}><TextInput  label="resources.users.fields.address.state" fullWidth source="address.state"/></Grid>
                <Grid item xs={6}><TextInput  label="resources.users.fields.address.country" fullWidth source="address.country"/></Grid>
                <Grid item xs={6}><TextInput  label="resources.users.fields.address.lat" fullWidth source="address.lat"/></Grid>
                <Grid item xs={6}><TextInput  label="resources.users.fields.address.lng" fullWidth source="address.lng"/></Grid>
            </Grid>

            <Grid container spacing={2} fullWidth>

                <Grid item xs={6}><TextInput  label="resources.users.fields.social.facebook"  fullWidth source="social.facebook"/> </Grid>
                <Grid item xs={6}><TextInput  label="resources.users.fields.social.instagram" fullWidth source="social.instagram"/></Grid>
                <Grid item xs={6}><TextInput  label="resources.users.fields.social.twitter" fullWidth source="social.twitter"/></Grid>

            </Grid>


            <ImageInput  source="avatar" accept="image/*" fullWidth>
                <ImageField source="formats.thumbnail.url" title="title"/>
            </ImageInput>
        
            <Link to="/forgot-password"  className={classes.forgotPassword} fullWidth>{translate('auth.change_password')}</Link>

        </SimpleForm>


</>
    );
};

export default UserProfileForm;
