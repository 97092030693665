import React from 'react';
import {MenuItemLink, Responsive, useTranslate} from 'react-admin';
import {useLocation} from "react-router-dom";

import {Divider, Paper} from "@material-ui/core";
import DashboardIcon from '@material-ui/icons/Dashboard';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ContactIcon from '@material-ui/icons/ContactSupport';
import ContactPage from '@material-ui/icons/Contacts';
import TakeAwayIcon from '@material-ui/icons/LocalDining';
import SettingsIcon from '@material-ui/icons/Settings';
import {makeStyles} from "@material-ui/core/styles";

const restaurantMenuItems = [
    {name: 'dashboard', isSubMenu: true, i18n: 'menu.dashboard', icon: <DashboardIcon/>},
    {name: 'take-away-requests', isSubMenu: true, i18n: 'menu.take-away-request', icon: <TakeAwayIcon/>},
    <Divider/>,
    {name: 'profile', isSubMenu: true, i18n: 'menu.settings', icon: <SettingsIcon/>},
];

const mainMenuItems = [
    {name: 'dashboard', i18n: 'menu.dashboard', icon: <DashboardIcon/>},
    {name: 'faq', i18n: 'menu.faq', icon: <ContactIcon/>},
    {name: 'support', i18n: 'menu.support', icon: <ContactPage/>},
];

function getCharityId(pathname) {
    const listPathname = pathname.split('/');
    if (listPathname.length < 3)
        return -1;

    if (listPathname[1] !== 'charities')
        return -1;

    return listPathname[2]
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingTop: '8px',
        paddingBottom: '8px'
    },
    button: {
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main}1e`,
            color: theme.palette.text.primary
        },
    }
}));

const Menu = ({onMenuClick, logout}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const location = useLocation();
    const charityID = getCharityId(location.pathname)

    return (
        <Paper elevation={0} className={classes.root}>
            {charityID !== -1 && <MenuItemLink
                key="dashboard"
                to="/dashboard"
                primaryText={translate("menu.exitCharity")}
                leftIcon={<ArrowBackIcon/>}
                onClick={onMenuClick}
                className={classes.button}
            />}

            {charityID !== -1 && <Divider/>}

            {charityID !== -1 && restaurantMenuItems.map((item, index) => {
                if (React.isValidElement(item)) return item;
                return (
                    <MenuItemLink
                        key={item.name+'-'+index}
                        to={`/charities/${charityID}/${item.name}`}
                        primaryText={translate(item.i18n)}
                        leftIcon={item.icon}
                        onClick={onMenuClick}
                        className={classes.button}
                    />
                );
            })}

            {charityID === -1 && mainMenuItems.map((item, index) => (
                <MenuItemLink
                    key={item.name+''+index}
                    to={`/${item.name}`}
                    primaryText={translate(item.i18n)}
                    leftIcon={item.icon}
                    onClick={onMenuClick}
                    className={classes.button}
                />
            ))}

            <Responsive
                small={logout}
                medium={null}
            />
        </Paper>
    )
};

export default Menu;

