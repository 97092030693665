import * as React from "react";
import {Card, CardContent, CardHeader, Grid} from '@material-ui/core';
import {useTranslate} from 'react-admin';

import {
    ResponsiveContainer,
    // AreaChart,
    // Area,
    LineChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Line
} from 'recharts';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         padding: theme.spacing(2)
//     },
//     button: {
//         minHeight: '60px',
//     }
// }));

const CharityCharts = (props) => {
    // const classes = useStyles();
    // let { restaurantId } = useParams();

    const translate = useTranslate();


    const data1 = [
        {name: 'Page A', uv: 1000, pv: 2400, amt: 2400, uvError: [75, 20]},
        {name: 'Page B', uv: 300, pv: 4567, amt: 2400, uvError: [90, 40]},
        {name: 'Page C', uv: 280, pv: 1398, amt: 2400, uvError: 40},
        {name: 'Page D', uv: 200, pv: 9800, amt: 2400, uvError: 20},
        {name: 'Page E', uv: 278, pv: null, amt: 2400, uvError: 28},
        {name: 'Page F', uv: 189, pv: 4800, amt: 2400, uvError: [90, 20]},
        {name: 'Page G', uv: 189, pv: 4800, amt: 2400, uvError: [28, 40]},
        {name: 'Page H', uv: 189, pv: 4800, amt: 2400, uvError: 28},
        {name: 'Page I', uv: 189, pv: 4800, amt: 2400, uvError: 28},
        {name: 'Page J', uv: 189, pv: 4800, amt: 2400, uvError: [15, 60]},
    ];


    const data2 = [
        {name: 'Page A', uv: 4000, pv: 2400, amt: 2400, time: 1},
        {name: 'Page B', uv: 3000, pv: 1398, amt: 2210, time: 3},
        {name: 'Page C', uv: 2000, pv: -9800, amt: 2290, time: 9},
        {name: 'Page D', uv: 2780, pv: 3908, amt: 2000, time: 10},
        {name: 'Page E', uv: 2500, pv: 4800, amt: 2181, time: 12},
        {name: 'Page F', uv: 1220, pv: 3800, amt: 2500, time: 16},
        {name: 'Page G', uv: 2300, pv: 4300, amt: 2100, time: 18},
        {name: 'Page H', time: 24},
    ];

    return (
        <Grid container spacing={2}  alignItems="stretch">

            <Grid item xs={12} md={6}>
                <Card>
                    <CardHeader title={translate('pos.charityDashboard.monthHistory')}/>
                    <CardContent>
                        <div style={{width: '100%', height: 300}}>
                            <ResponsiveContainer>
                                <LineChart data={data1}>
                                    <XAxis dataKey="name"/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                                    <Line type="monotone" dataKey="uv" stroke="#8884d8"/>
                                    <Line type="monotone" dataKey="pv" stroke="#82ca9d"/>
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={6}>
                <Card>
                    <CardHeader title={translate('pos.charityDashboard.contributionHistoryChart')}/>
                    <CardContent>
                        <div style={{width: '100%', height: 300}}>
                            <ResponsiveContainer>
                                <LineChart>
                                    <XAxis dataKey="name"/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                                    <Line type="monotone" dataKey="uv" stroke="#8884d8"/>
                                    <Line type="monotone" dataKey="pv" stroke="#82ca9d"/>
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={6}>
                <Card>
                    <CardHeader title={translate('pos.charityDashboard.averageMonthlyRating')}/>
                    <CardContent>
                        <div style={{width: '100%', height: 300}}>
                            <ResponsiveContainer>
                                <LineChart data={data2}>
                                    <XAxis dataKey="name"/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                                    <Line type="monotone" dataKey="uv" stroke="#8884d8"/>
                                    <Line type="monotone" dataKey="pv" stroke="#82ca9d"/>
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>
            </Grid>


            <Grid item xs={12} md={6}>
                <Card>
                    <CardHeader title={translate('pos.charityDashboard.booking30days')}/>
                    <CardContent>
                        <div style={{width: '100%', height: 300}}>
                            <ResponsiveContainer>
                                <LineChart>
                                    <XAxis dataKey="name"/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                                    <Line type="monotone" dataKey="uv" stroke="#8884d8"/>
                                    <Line type="monotone" dataKey="pv" stroke="#82ca9d"/>
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>
            </Grid>

        </Grid>

    );
}

export default CharityCharts;
