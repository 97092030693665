import * as React from "react";
import {DateTimeInput, NumberInput, ReferenceInput, SelectInput, ReferenceArrayInput, SelectArrayInput,ChipField,
        SimpleForm, TextInput, ArrayInput, SimpleFormIterator, AutocompleteInput } from 'react-admin';
import {useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    hidden: {
        display: "none"
    },
});


const TakeAwayRequestForm = props => {
    const { charityId } = useParams();
    const classes = useStyles();

    return (
        <SimpleForm {...props}>
            <TextInput fullWidth source="id" className={classes.hidden} disabled/>
            <ReferenceInput reference="charities" source="charitt" defaultValue={charityId} className={classes.hidden} disabled>
                <SelectInput optionText="name" />
            </ReferenceInput>
         
            <TextInput  fullWidth source="description"
                       multiline/>
            <DateTimeInput fullWidth source="date" />

     
            <ReferenceArrayInput   source="other_products" reference="restaurant-products" fullWidth>
                        <SelectArrayInput>
                                    <ChipField source="name" />
                                </SelectArrayInput>
            </ReferenceArrayInput> 

            <ReferenceInput  label="resources.take-away-requests.fields.restaurant" source="restaurant" reference="restaurants" fullWidth disabled>
                            <AutocompleteInput optionText="name"  />
                        </ReferenceInput>

            <ArrayInput source="entries">
                    <SimpleFormIterator>
                      
                        <ReferenceInput  label="resources.take-away-requests.fields.name" source="recipe.id" reference="recipes" fullWidth>
                            <AutocompleteInput optionText="name"  />
                        </ReferenceInput>
                        <TextInput multiline   source="description" fullWidth/>

                        <NumberInput   source="servings" fullWidth/>
                        <NumberInput   source="price" fullWidth/>
                        <NumberInput   source="tax" fullWidth/>

                    </SimpleFormIterator>
                </ArrayInput>


        </SimpleForm>
    );
};

export default TakeAwayRequestForm;
