
import * as React from 'react';
import {useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import authProvider from "../providers/authProvider";

import {
    Button,
    Card,
    CardActions,
    CircularProgress,
    TextField,
} from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {Notification, useTranslate, Link, useNotify, useRefresh, useLogin} from 'react-admin';

import { lightTheme } from './themes';
import {useHistory, useLocation} from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'url(imgs/kitchen.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    logo: {
        width: '120px',
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    formWrapper: {
        minHeight: '328px'
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    backToLoginWrapper:{
        textAlign: 'center',
        margin: '1em 0',
    }
  
}));

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
        required
    />
);

const { Form } = withTypes();

const ResetPasswordForm = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const code = query.get('code')??null;
    const location = useLocation();
    const history = useHistory();
    const login = useLogin();

    const handleSubmit = useCallback((values) => {
        setLoading(true);
        
       
        authProvider.resetPassword({...values, code})
            .then(async (res) => {
                if (!res.ok) {
                    throw res.error()
                }
                notify(
                    translate('auth.messages.reset_password.success.title'),
                    'success',
                    {
                        _: translate('auth.messages.reset_password.success.description')
                    }
                );
                
                const userString = localStorage.getItem('user');
                if(userString){
                    history.push(`/`)
                }
                else{
                    const data = await res.json()
                    login({username: data.user.username, password: values.password}, location.state ? location.state.nextPathname : '/')     
                }
                
            }).catch(() => {
                notify(
                    translate('auth.messages.reset_password.error.title'),
                    'warning',
                    {
                        _: translate('auth.messages.reset_password.error.description')
                    }
                );
            })
            .finally(() => {
                setLoading(false);
                refresh();
            }
            );
    }, [notify, refresh, translate, code,  location, login, history]);

    const validate = (values) => {
        const errors = {};
        if (!values.password) {
            errors.email = translate('ra.validation.required');
        }
        if (!values.passwordConfirmation) {
            errors.email = translate('ra.validation.required');
        }

        if (values.password !== values.passwordConfirmation) {
            errors.email = translate('auth.messages.reset_password.error.different_password');
        }
        
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}  className={classes.formWrapper}>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                {/* <Avatar className={classes.icon}>
                                    <LockIcon />
                                </Avatar> */}
                                <img className={classes.logo} src="logo-with-text-red.svg" alt="sabores cv logo"></img>
                            </div>
                            <div className={classes.hint}>
                            {translate('auth.reset_password')}
                            </div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        name="password"
                                        // @ts-ignore
                                        component={renderInput}
                                        label={translate('auth.password')}
                                        disabled={loading}
                                        type="text"
                                        require
                                    />

                                    <Field
                                        autoFocus
                                        name="passwordConfirmation"
                                        // @ts-ignore
                                        component={renderInput}
                                        label={translate('auth.confirm_password')}
                                        disabled={loading}
                                        type="text"
                                        require
                                    />
                                </div>
                           
                          
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                     variant="contained"
                                     type="submit"
                                     color="primary"
                                     disabled={loading}
                                     fullWidth
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {translate('auth.send')}
                                </Button>
                            </CardActions>
                            <div className={classes.backToLoginWrapper}>
                                <Link to="/login" >Back to login page</Link>
                            </div>
                            

                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    );
};

ResetPasswordForm.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const ResetPasswordPage = (props) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <ResetPasswordForm {...props} />
    </ThemeProvider>
);

export default ResetPasswordPage;