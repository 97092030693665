
export const TOGGLE_LIGHTS = 'TOGGLE_LIGHTS';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

export const toggleLights = (light) => ({
    type: TOGGLE_LIGHTS,
    payload: light,
});

export const changeLocale = (locale) => ({
    type: CHANGE_LOCALE,
    payload: locale,
});
