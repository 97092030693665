import * as React from "react";
import {SimpleForm, TextInput, required, email, useTranslate } from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles({
    title: {
        fontWeight: "bold",
        marginBottom: '16px',
    },
});


const ContactForm = props => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <>
       
         <SimpleForm {...props}>

            <div className={classes.title} >{translate('resources.contacts.sendMessage')}</div>
            <TextInput fullWidth source="from"  type={'email'}  validate={[required(), email()]}/>
            <TextInput fullWidth source="subject"   validate={[required()]}/>
            <TextInput fullWidth source="message"  multiline rows={3}  validate={[required()]}/>

        </SimpleForm>
        </>
    );
};

export default ContactForm;
