import * as React from 'react';
import { useTranslate } from 'react-admin';

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import CardWithIcon from './CardWithIcon';

const SolidarityContributionLastMonth = ({ restaurantId, value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to={`/restaurants/${restaurantId}/take-away-requests`}
            icon={FavoriteBorderIcon}
            title={translate('pos.charityDashboard.charityContribution')}
            subtitle={value}
        />
    );
};

export default SolidarityContributionLastMonth;
