import * as React from 'react';
import { Route } from "react-router-dom";

import Dashboard from "./components/dashboard/Dashboard";
import CharityGridList from "./components/charities/CharityGridList";
import CharityDashboard from './components/charities/CharityDashboard';

import TakeAwayRequestList from './components/takeAwayRequests/TakeAwayRequestList';
import TakeAwayRequestEdit from './components/takeAwayRequests/TakeAwayRequestEdit';
import TakeAwayRequestCreate from './components/takeAwayRequests/TakeAwayRequestCreate';
import CharityProfileEdit from './components/charities/CharityProfileEdit';
// import Support from './components/support/support';
import ContactCreate from './components/support/ContactCreate';
import Faq from './components/faq/Faq';
import Settings from "./components/settings/Settings";
import UserProfileEdit from './components/user/UserProfileEdit';
import ForgotPasswordPage from './layout/ForgotPassword';
import ResetPasswordPage from './layout/ResetPassword';

const routes = [
    <Route path="/dashboard" component={Dashboard} />,

    <Route path="/charities/:charityId/take-away-requests/create" component={TakeAwayRequestCreate} />,
    <Route path="/charities/:charityId/take-away-requests/:id" component={TakeAwayRequestEdit} />,
    <Route path="/charities/:charityId/take-away-requests" component={TakeAwayRequestList} />,

    <Route path="/charities/:charityId/profile" component={CharityProfileEdit} />,

    <Route path="/charities/:charityId" component={CharityDashboard} />,
    <Route path="/charities" component={CharityGridList} />,
    <Route path="/support" component={ContactCreate} />,
    <Route path="/faq" component={Faq} />,
    <Route path="/user-profile" component={UserProfileEdit} />,
    <Route path="/forgot-password" component={ForgotPasswordPage}  noLayout/>,
    <Route path="/reset-password" component={ResetPasswordPage}  noLayout/>,
    // <Route path="/support" component={Support} />,
    <Route path="/settings" component={Settings} />,
];
export default routes;


