import {CHANGE_LOCALE, TOGGLE_LIGHTS} from "./actions";

const localeReducer = (
    previousState = 'en',
    { type, payload }
) => {
    if (type === CHANGE_LOCALE) {
        return payload
    }
    return previousState
}

const themeReducer = (
    previousState = 'light',
    { type, payload }
) => {
   
    if (type === TOGGLE_LIGHTS) {
        return payload ? 'light' : 'dark'
    }
    return previousState
};

const reducers = {
    localeReducer,
    themeReducer
};

export default reducers;
