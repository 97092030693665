import * as React from "react";
import {Edit} from 'react-admin';
import {useParams} from "react-router-dom";
import TakeAwayRequestForm from "./TakeAwayRequestForm";


const TakeAwayRequestEdit = props => {
    const {id} = useParams();
    
    return (
        <Edit basePath={`/`} resource={`take-away-requests`} id={id}>
           <TakeAwayRequestForm {...props}/>
        </Edit>
    );
};

export default TakeAwayRequestEdit;
