import * as React from "react";
import {Admin as RAAdmin, Resource} from 'react-admin';
import {Provider, useSelector} from 'react-redux'
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {createHashHistory} from 'history'

import englishMessages from './i18n/en';
import portugueseMessages from './i18n/pt';
import routes from "./routes";
import dataProvider from "./providers/dataProvider";
import authProvider from "./providers/authProvider";
import LoginPage from "./layout/Login";
import Layout from "./layout/Layout";
import TakeAwayRequestList from "./components/takeAwayRequests/TakeAwayRequestList";
import TakeAwayRequestEdit from "./components/takeAwayRequests/TakeAwayRequestEdit";
import TakeAwayRequestCreate from "./components/takeAwayRequests/TakeAwayRequestCreate";
import Dashboard from "./components/dashboard/Dashboard";
import ContactCreate from "./components/support/ContactCreate";
import customReducers from "./redux/reducers";
import createAdminStore from "./redux/createAdminStore";
import UserProfileEdit from "./components/user/UserProfileEdit";


const i18nProvider = (initialLocale) => polyglotI18nProvider(locale => {
    if (locale === 'pt') {
        return portugueseMessages;
    }
    return englishMessages;
}, initialLocale || 'en');

const history = createHashHistory()

const App = () => {
    return (
        <Provider
            store={createAdminStore({
                authProvider,
                dataProvider,
                history,
                customReducers: {
                    theme: customReducers.themeReducer,
                    locale: customReducers.localeReducer
                },
            })}
        >
            <Admin/>
        </Provider>
    )
};

const Admin = () => {
    const locale = useSelector(state => state.locale)
    return (
        <RAAdmin
            i18nProvider={i18nProvider(locale)}
            dashboard={Dashboard}
            authProvider={authProvider}
            dataProvider={dataProvider}
            loginPage={LoginPage}
            layout={Layout}
            // theme={myTheme}
            customRoutes={routes}
            history={history}
            disableTelemetry //In production, react-admin applications send an anonymous request on mount to a telemetry server operated by marmelab.
        >

            {/* <Resource name="categories" list={ListGuesser} /> */}
            <Resource name="categories" />
            <Resource name="restaurants"/>
            <Resource name="products"/>
            <Resource name="users" edit={UserProfileEdit}/>
            <Resource name="units"/>
            <Resource name="charities"/>
            <Resource name="contacts" create={ContactCreate}/>
            <Resource name="recipes" />
            <Resource name="restaurant-products"/>
            <Resource name="restaurant-menus" />
            <Resource name="take-away-requests" list={TakeAwayRequestList} edit={TakeAwayRequestEdit}
                      create={TakeAwayRequestCreate}/>
        </RAAdmin>
    );
};

export default App;
