import * as React from "react";
import {Edit, } from 'react-admin';
import UserProfileForm from "./UserProfileForm";


const UserProfileEdit = props => {

    const accountString = localStorage.getItem('user');
    let userId = ''
    if (accountString) {
        userId = JSON.parse(accountString).id;
    }

    return (
        <>
            <Edit basePath={`/`} resource={`users`} id={userId}>
                <UserProfileForm {...props}/>
            </Edit>
        
        </>
    );
};

export default UserProfileEdit;
