import * as React from "react";
import {useParams} from "react-router-dom";

import {Grid} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";


import CharityCharts from "./CharityCharts";
import IncomeLastMonth from "./IncomeLastMonth";
import SolidarityContributionLastMonth from "./SolidarityContributionLastMonth";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    button: {
        minHeight: '60px',
    }
})); 

const CharityDashboard = () => {
    const classes = useStyles();
    const {charityId} = useParams();

   
    return (
        <div className={classes.root}>
           

             <Grid container spacing={2}  alignItems="stretch">

                <Grid item sm={6} md={6}>
                    <IncomeLastMonth restaurantId={charityId} value={3300}/>
                </Grid>
               
                <Grid item sm={6} md={6}>
                    <SolidarityContributionLastMonth restaurantId={charityId} value={333}/>
                </Grid>
            </Grid>

            <CharityCharts/> 

        </div>
    );
}

export default CharityDashboard;
