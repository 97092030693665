import {createMuiTheme} from "@material-ui/core/styles";

export const darkTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#AA7508',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#6D191E',
            light: '#A04745',
            dark: '#3E0000',
            contrastText: '#FFFFFF'
        },
        background: {
            paper: '#323232',
            default: '#222222',
        },
        text: {
            primary: '#FFFFFF'
        },
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
    overrides: {
        RaLayout: {
            root: {},
            content: {
                padding: '16px !important',
            },
        },
        RaToolbar: {
            toolbar: {
                backgroundColor: '#222222',
            }
        },
        RaMenuItemLink: {
            root: {
                borderLeft: '3px solid transparent',
            },
            active: {
                borderLeft: '3px solid #6D191E',
            },
            icon: {
                minWidth: 'auto',
                margin: '0 24px 0 0',
            }
        },
        MuiMenuItem: {
            root: {
                paddingTop: '12px',
                paddingBottom: '12px',
            },
        },
        RaLogout: {
            menuItem: {
                borderLeft: '3px solid transparent',
            },
            icon: {
                minWidth: 'auto',
                margin: '0 24px 0 0',
            }
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit'
            }
        },
        MuiDivider: {
            root: {
                margin: '4px 16px'
            }
        },
        MuiPaper: {
            root: {
                border: 'none',
                backgroundClip: 'padding-box',
            },
        },
        MuiButton: {
            textPrimary: {
                color: '#E0A440'
            }
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit',

                },
            },

        },
        MuiAppBar: {
            iconTheme: {
                backgroundColor: '#333333',
            },
            colorPrimary: {
                border: 'none',
            },
            colorSecondary: {
                fontWeight: 700,
                backgroundColor: '#AA7508',
                color: '#FFFFFF',
                border: 'none',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiSnackbarContent: {
            root: {
                border: 'none',
            },
        },
    },
    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: true,
        },
    },
});

export const lightTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#6D191E',
            light: '#A04745',
            dark: '#3E0000',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#E0A440',
            light: '#FFD570',
            dark: '#AA7508',
            contrastText: '#000000',
        },
        background: {
            paper: '#ffffff',
            default: '#ffffff',
        },
        text: {
            primary: '#000000'
        },
        type: 'light',
    },
    overrides: {
        RaLayout: {
            root: {
            },
            content: {
                padding: '16px !important',
            },
        },
        RaToolbar: {
            toolbar: {
                backgroundColor: '#ffffff',
            }
        },
        RaMenuItemLink: {
            root: {
                borderLeft: '3px solid transparent',
            },
            active: {
                borderLeft: '3px solid #6D191E',
            },
            icon: {
                minWidth: 'auto',
                margin: '0 24px 0 0',
            }
        },
        MuiMenuItem: {
            root: {
                paddingTop: '12px',
                paddingBottom: '12px',
            },
        },
        RaLogout: {
            menuItem: {
                borderLeft: '3px solid transparent',
            },
            icon: {
                minWidth: 'auto',
                margin: '0 24px 0 0',
            }
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit'
            }
        },
        MuiDivider: {
            root: {
                margin: '4px 16px'
            }
        },
        MuiPaper: {
            root: {
                border: 'none',
                backgroundClip: 'padding-box',
            },
        },
        MuiButton: {
            textPrimary: {
                color: '#6D191E'
            }
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit',

                },
            },

        },
        MuiAppBar: {
            iconTheme: {
                backgroundColor: '#333333',
            },
            colorPrimary: {
                border: 'none',
            },
            colorSecondary: {
                fontWeight: 700,
                backgroundColor: '#6D191E',
                color: '#FFFFFF',
                border: 'none',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiSnackbarContent: {
            root: {
                border: 'none',
            },
        },
    },
    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: true,
        },
    },
});
