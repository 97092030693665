import * as React from 'react';
import {forwardRef} from 'react';
import {AppBar, UserMenu, MenuItemLink, useTranslate} from 'react-admin';

import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    languageSelector: {
        fontWeight: 700,
        fontSize: '1.2rem',
    }
});

const ConfigurationMenu = forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/settings"
            primaryText={translate('pos.settings')}
            leftIcon={<SettingsIcon/>}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const UserProfileMenu = forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/user-profile"
            primaryText={translate('pos.user_profile')}
            leftIcon={<AccountCircleIcon/>}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const CustomUserMenu = (props) => (
    <UserMenu {...props}>
        <UserProfileMenu/>
        <ConfigurationMenu/>

    </UserMenu>
);

/*const ThemeButton = () => {
    const theme = useSelector((state) => state.theme);
    const dispatch = useDispatch();

    return (
        <IconButton
            color="inherit"
            onClick={() => {
                dispatch(changeTheme(theme === 'light' ? 'dark' : 'light'))
                localStorage.setItem("theme_scv", theme === 'light' ? 'dark' : 'light');
            }}
        >
            {theme === 'light' ? <Brightness4Icon/> : <Brightness7Icon/>}
        </IconButton>
    );
};*/

/*const I18nButton = () => {
    const locale = useLocale();
    const setLocale = useSetLocale();
    const classes = useStyles();
    const languageLocalStorage = localStorage.getItem("language_scv");
    const language = !languageLocalStorage ? (locale === 'en' ? 'en' : 'pt') : languageLocalStorage;

    return (
        <Button
            className={classes.languageSelector}
            onClick={() => {
                const newLocale = language === 'en' ? 'pt' : 'en'
                localStorage.setItem("language_scv", newLocale);
                console.log(`new locale: ${newLocale}`);
                return setLocale(newLocale);
            }}
        >
            {language === 'en' ? 'pt' : 'en'}
        </Button>
    );
};*/

const CustomAppBar = (props) => {
    const classes = useStyles();
    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu/>}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer}/>
        </AppBar>
    );
};

export default CustomAppBar;
