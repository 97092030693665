import {Filter, TextInput, useTranslate, ReferenceInput, AutocompleteInput, useLocale} from "react-admin"
import * as React from "react";
import {DateTimeInput} from 'react-admin-material-datepicker';
import DateFnsUtils from "@date-io/date-fns";
import {enUS, pt} from "date-fns/locale";

export const TakeAwayRequestFilter = (props) => {
    const locale = useLocale();
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput label="pos.search" source="q" alwaysOn/>
            <ReferenceInput source="charity" reference="charities">
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <DateTimeInput
                label={translate('resources.take-away-requests.fields.date_gte')}
                source="date_gte"
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel')
                }}
                providerOptions={{utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS}}
            />
            <DateTimeInput
                label={translate('resources.take-away-requests.fields.date_lte')}
                source="date_lte"
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel')
                }}
                providerOptions={{utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS}}
            />
            <DateTimeInput
                label={translate('resources.take-away-requests.fields.created_at_gte')}
                source="created_at_gte"
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel')
                }}
                providerOptions={{utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS}}
            />
            <DateTimeInput
                label={translate('resources.take-away-requests.fields.created_at_lte')}
                source="created_at_lte"
                options={{
                    format: 'dd/MM/yyyy, HH:mm:ss',
                    ampm: false,
                    clearable: true,
                    clearLabel: translate('dialog.actions.clearLabel'),
                    cancelLabel: translate('dialog.actions.cancelLabel'),
                    okLabel: translate('dialog.actions.okLabel')
                }}
                providerOptions={{utils: DateFnsUtils, locale: locale === 'pt' ? pt : enUS}}
            />
        </Filter>
    )
};
