/* eslint-disable import/no-anonymous-default-export */
const baseURL = 'https://api.saborescv.com';

export default {
    // called when the user attempts to log in
    // login: ({ username }) => {
    //     localStorage.setItem('username', username);
    //     // accept all username/password combinations

    //     return Promise.resolve();
    // },

    login: ({username, password}) => {
        
        const request = new Request(baseURL + '/auth/local', {
            method: 'POST',
            body: JSON.stringify({identifier: username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),

        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('auth', JSON.stringify(auth));
                localStorage.setItem('user', JSON.stringify(auth.user))
                localStorage.setItem('username', auth.user.username)
                return Promise.resolve();
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },


    // called when the user clicks on the logout button
    logout: () => {
        clearLocalStorage()
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({status}) => {
        if (status === 401 || status === 403) {
            clearLocalStorage();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return !!localStorage.getItem('user')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),

    getIdentity: () => {
        try {
            const {id, username, avatar} = JSON.parse(localStorage.getItem('user'));
            const userAvatar = avatar ? avatar.url : null;
            return Promise.resolve({id, fullName: username, avatar: userAvatar});
        } catch (error) {
            return Promise.reject(error);
        }
    },

    forgotPassword: ({email}) => {
        return fetch(`${baseURL}/auth/forgot-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        })
    },

    resetPassword: ({code, password, passwordConfirmation}) => {
        return fetch(`${baseURL}/auth/reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({code, password, passwordConfirmation })
        })
    }
};


function clearLocalStorage() {
    localStorage.removeItem('user');
    localStorage.removeItem('auth');
    localStorage.removeItem('username');
}
