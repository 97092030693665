import * as React from 'react';
import {useSelector} from 'react-redux';
import {Layout, Sidebar} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";

import AppBar from './AppBar';
import Menu from './Menu';
import {darkTheme, lightTheme} from './themes';

import logoSvg from '..//logo-scv.svg';

const useStyles = makeStyles({
    root: {
        '&::before': {
            content: '" "',
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            opacity: '0.3',
            backgroundImage: `url(${logoSvg})`,
            backgroundSize: '192px 192px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            zIndex: -1
        }
    },
    sidebar: {
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 2px 1px 3px 0px'
    },
});

const CustomSidebar = (props) => {
    const classes = useStyles();
    return <Sidebar {...props} className={classes.sidebar} size={200}/>;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const classes = useStyles();
    const theme = useSelector((state) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );
    return (
        <Layout
            {...props}
            appBar={AppBar}
            sidebar={CustomSidebar}
            menu={Menu}
            theme={theme}
            className={classes.root}
        />
    );
};
