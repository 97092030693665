import * as React from "react";
import {useHistory} from 'react-router-dom';
import {Loading, useDataProvider, useRefresh} from "react-admin";

import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import CharityCard from "./CharityCard";
import {useEffect, useState} from "react";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    }
}));

const CharityGridList = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        dataProvider.myCharities('charities')
            .then((response) => {
                if (response && response.data) {
                    setData(response.data)
                } else {
                    setData([]);
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false);
                refresh();
            })
    }, [dataProvider, refresh])



    if (loading) return <Loading/>
    /*if (error) {
        return <p>{JSON.stringify(error)}</p>
    }*/

    if (data.length === 1) {
        history.push(`/charities/${data[0].id}`);
    }


    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={2}
                direction="row"
            >
                {data.map((charity) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={"charity-"+charity.id}>
                        <CharityCard
                            key={charity.id}
                            value={charity}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}


export default CharityGridList;
